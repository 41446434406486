<template>
  <v-container
          fill-height
          fluid
          grid-list-xl
  >
    <v-layout
            justify-center
            wrap
    >
      <template v-if="loaded">
        <data-table ref="datatable"
                    :key="'Subscriptions'"
                    :api-end-point="apiurl"
                    :search="search"
                    :sort-order="sortOrder"
                    :table-name="'Self-care'"
                    :user_id="user_id"
                    :user_type="user_type"
                    :pre-selected-filters="filters.selectedFilters"
                    :isDetail="true"
                    :is-subscription-transaction="true"
                    :isExport="false"
                    v-on:refreshData="getSubscriptionsStats"
                    v-on:refreshSelfData="getSubscriptionsRevenue"
        >
         <template slot="filters">
             <filters :filters="filters.subscriptionTime"></filters>
             <filters :filters="filters.typeFilter"></filters>
<!--             <filters :filters="filters.subscriptionType"></filters>-->
             <filters :filters="filters.subscriptionStatus"></filters>

           <!-- Filter those bookings which are booked by clients who registered on MOT from this UTM Source -->

           <multi-select-filters class="mr-2"
                                 :filter-id="'utm_source_filter'"
                                 :filters="filters.subscriptionSourceFilter"
                                 :placeholder="'Select source'"
                                 :column="'utm_source'"
                                 :query-type="'external'"
                                 :operator="'in'"
                                 :relation-ship-name="''"
           ></multi-select-filters>

           <!-- Filter those bookings which are booked by clients who registered on MOT from this UTM Campaign-->

           <multi-select-filters :filter-id="'utm_campaign_filter'"
                                 :filters="filters.subscriptionCampaignFilters"
                                 :placeholder="'Select campaign'"
                                 :column="'utm_campaign'"
                                 :query-type="'external'"
                                 :operator="'in'"
                                 :relation-ship-name="''"
           ></multi-select-filters>

           <template v-if="filters.orgFilters.length > 0">
             <multi-select-filters :filter-id="'org_filter'"
                                   class="ml-2"
                                   :filters="filters.orgFilters"
                                   :placeholder="'Select organisation'"
                                   :column="'org_id'"
                                   :query-type="'external'"
                                   :relation-ship-name="''"
                                   :operator="'in'"
                                   :relation-ship-type="''"
             ></multi-select-filters>
             </template>

           <!-- Filter bookings according to these plans-->
             <template v-if="filters.planFilters.length > 0">
             <multi-select-filters :filter-id="'plan_filter'"
                                   class="ml-2"
                                   :filters="filters.planFilters"
                                   :placeholder="'Select Plan'"
                                   :column="'plan_id'"
                                   :query-type="'external'"
                                   :relation-ship-name="''"
                                   :operator="'in'"
                                   :relation-ship-type="''"
             ></multi-select-filters>
             </template>

         </template>
          <template slot="stats">
            <div class="display-flex flex-row">
              <span class="mt-2 ml-2">
                Cancelled / Expired = {{ cancelledSubscriptions  }}
              </span>
              <span class="mt-2 ml-2">
                Total active = {{ activeSubscriptions  }}
              </span>
              <span class="mt-2 ml-2">
                Subscription Revenue = £{{ totalRevenue  }}
              </span>
            </div>
          </template>
        </data-table>
      </template>

      <subscription-transactions v-if="subscriptionTransactionsDialog"
                                 :visible="subscriptionTransactionsDialog"
                                 :rowData="selectedSubscription"
                                 v-on:hide="hideSubscriptionTransactionsModal">

      </subscription-transactions>

    </v-layout>
  </v-container>
</template>

<script>
  import DataTable from "../components/common/dataTable/DataTable";
  import Filters from "../components/common/dataTable/filters/Filters";
  import SubscriptionTransactions from "../components/main/SubscriptionTransactions";
  import MultiSelectFilters from "@/components/common/dataTable/filters/MultiSelectFilters";

  export default {
    name: "Subscriptions",
    components: {
        DataTable,
        Filters,
        SubscriptionTransactions,
        MultiSelectFilters
    },
    props: {
      user_id: {
        user_id: [Number, String]
      },
      user_type: {
        type: String
      },
      header:{
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
          apiurl: 'subscriptions' ,
          search:{
            placeholder:  'Search by client, id',
            searchAble: true
          },
          sortOrder: [
            {
              field: 'id',
              sortField: 'id',
              direction: 'desc'
            }
          ],
          filters:[],
          selected_filters:[],
          loaded: false,
          selectedSubscription: null,
          action: 'add',
          activeSubscriptions: 0,
          cancelledSubscriptions: 0,
          totalRevenue: 0,
          detailUrl: 'subscription/details/',
          subscriptionTransactionsDialog: false,
      }
    },
    destroyed() {
      this.$events.off('get-revenue');
    },
    created() {
      this.fetchFilters();
      let subscriptionId =  this.$route.query.subscription_id;
      if (subscriptionId) {
          this.search.data = subscriptionId ;
      }
    },
    methods:{
        hideSubscriptionTransactionsModal() {
            this.selectedSubscription = null;
            this.subscriptionTransactionsDialog = false;
        },
        fetchFilters() {
            this.$http.get(this.url+'filters/subscriptions').then((res) => {
                if(res.data) {
                    this.filters = res.data;
                    this.loaded = true;
                } else {
                    alert('there is some problem');
                }
            });
        },
        removeSortFromUrl(key, sourceURL) {
            let rtn = sourceURL.split("?")[0],
                param,
                params_arr = [],
                queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
            if (queryString !== "") {
                params_arr = queryString.split("&");
                for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                    param = params_arr[i].split("=")[0];
                    if (param === key) {
                        params_arr.splice(i, 1);
                    }
                }
                rtn = rtn + "?" + params_arr.join("&");
            }
            return rtn;
        },
        getSubscriptionsStats(query) {
          let originalURL = this.url + "subscriptions/stats?" + query;
          let alteredURL  =  this.removeSortFromUrl("sort", originalURL);
          this.$http.get(alteredURL).then( response => {
            this.activeSubscriptions = response.data.active_subscriptions;
            this.cancelledSubscriptions = response.data.cancelled_subscriptions;
          }).catch( error => {
              this.$toastr("error", error.response.data.message, "");
          });
        },
      getSubscriptionsRevenue(query) {
        let originalURL = this.url + "subscriptions/revenue?" + query;
        let alteredURL  =  this.removeSortFromUrl("sort", originalURL);
        this.$http.get(alteredURL).then( response => {
          this.totalRevenue = response.data.revenue;
        }).catch(error => {
          this.$toastr("error", error.response.data.message, "");
        });
      },
    },
      events: {
          'open-details': function(data) {
              this.$router.push(this.detailUrl + data.id);
          },
          'open-transactions': function(data) {
              this.selectedSubscription = data;
              this.subscriptionTransactionsDialog = true;
          },
      },
  }
</script>
